@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800&display=swap');

:root {
  --light-blue: #F6F9FE;
  --dark-blue: #2F3E94;
  --dark-accent: #3A4CAD;
  --accent: #6366F1;
  --gray-light: #F7F8FA;
  --gray: #6B6B6B;
  --white: #FFFFFF;
}

/* General Styles */
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background-color: var(--light-blue);
  color: var(--dark-blue);
}

.hero-fullscreen {
  height: 100vh;
  background: linear-gradient(to bottom, #edf2fb, #e2eafc);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Hero Section */
/* .hero-section {
  height: 100vh;
  background: linear-gradient(to bottom, #edf2fb, #e2eafc);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */

h1 {
  font-size: 6rem;
  font-weight: 800;
  color: var(--dark-blue);
}

p {
  font-size: 1.5rem;
  color: var(--accent);
}

.grid {
  display: grid;
  gap: 24px;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.btn-primary {
  background-color: var(--accent);
  color: var(--white);
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--dark-accent);
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--dark-blue);
  color: var(--white);
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: var(--gray);
  transform: translateY(-2px);
}
